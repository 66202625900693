import React, { useCallback } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { Box, Grid, PageGrid } from '@sparky';
import { styled } from '@sparky/stitches';

const StyledContainer = styled('div', {
  position: 'relative',
  '@lg': {
    position: 'static',
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  width: '100vw',
  maxWidth: '100%',
  position: 'fixed',
  left: 0,
  zIndex: 10,
  marginTop: '$2',
  backgroundColor: '$backgroundPrimary',
  boxShadow: '$l',
  '@lg': {
    position: 'absolute',
    top: '$10',
    marginTop: 0,
  },
});

type ChatbotDialogWrapperProps = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchChatBotDialogWrapper: React.FC<ChatbotDialogWrapperProps> = ({ children, trigger, isOpen, setOpen }) => {
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen?.(isOpen);
    },
    [setOpen],
  );

  return (
    <StyledContainer>
      <DialogPrimitive.Root onOpenChange={onOpenChange} open={isOpen}>
        <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
        <StyledContent>
          <PageGrid>
            <Grid.Item gridColumn={'1 / -1'}>
              <Box
                paddingY={{
                  initial: '16',
                  md: '24',
                }}>
                {children}
              </Box>
            </Grid.Item>
          </PageGrid>
        </StyledContent>
      </DialogPrimitive.Root>
    </StyledContainer>
  );
};

export default SearchChatBotDialogWrapper;
