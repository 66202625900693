import React, { FC, useState } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useChat } from '@components/chat/useChat';
import SearchChatBotDialogWrapper from '@custom-components/SearchChatBotDialogWrapper';
import { ComponentRendering, useContent } from '@sitecore/common';
import { SearchChatBotRendering } from '@sitecore/types/SearchChatBot';
import { Box, Button, Form, Grid, Heading, IconButton, InputText, Stack } from '@sparky';
import { CloseIcon, SearchIcon } from '@sparky/icons';
import { useI18nTranslation } from '@sparky/providers';

interface FormFields {
  question?: string;
}

interface SearchChatBotProps {
  platform: 'mobile' | 'desktop';
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SearchChatBotFormProps {
  closeButton?: React.ReactNode;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchChatBotForm: FC<SearchChatBotFormProps> = ({ closeButton, setIsOpen }) => {
  const [mount] = useChat();
  const { register, handleSubmit, reset } = useForm<FormFields>();
  const content = usePlaceholderComponentContent<SearchChatBotRendering>('jss-main-navigation-right', 'SearchChatBot');

  const submitForm: SubmitHandler<FormFields> = ({ question }) => {
    mount({
      visibility: 'open',
      askText: question,
      topic: content?.fields.defaultChatTopic.value,
    });
    setIsOpen && setIsOpen(false);
    reset();
  };
  if (!content?.fields) return null;
  const { fields } = content;

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Grid gap={6} columns={2}>
        <Grid.Item gridColumn={'1/-1'}>
          <Stack direction={'row'} alignY={'start'} alignX={'justify'}>
            <Heading as="h2" size={'L'}>
              {fields.title.value}
            </Heading>
            {closeButton}
          </Stack>
        </Grid.Item>

        <Grid.Item
          gridColumn={{
            initial: '1/-1',
            md: '1',
          }}>
          <InputText
            label={''}
            {...register('question', { maxLength: 75 })}
            placeholder={fields.placeholderText.value}></InputText>
        </Grid.Item>

        <Grid.Item gridColumn={'1/-1'}>
          <Box>
            <Button type={'submit'}>{fields.buttonText.value}</Button>
          </Box>
        </Grid.Item>
      </Grid>
    </Form>
  );
};

const SearchChatBot: FC<SearchChatBotProps> = ({ platform, setIsOpen }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeText = useI18nTranslation('close');
  const content = usePlaceholderComponentContent<SearchChatBotRendering>('jss-main-navigation-right', 'SearchChatBot');

  if (!content?.fields) return null;

  if (platform === 'mobile') {
    return (
      <Box paddingY={4} paddingX={8}>
        <SearchChatBotForm setIsOpen={setIsOpen} />
      </Box>
    );
  }

  return (
    <SearchChatBotDialogWrapper
      trigger={
        <IconButton label={content?.fields.searchIconAriaText.value || ''}>
          <SearchIcon></SearchIcon>
        </IconButton>
      }
      isOpen={isDialogOpen}
      setOpen={setIsDialogOpen}>
      <SearchChatBotForm
        closeButton={
          <DialogPrimitive.Close asChild>
            <IconButton label={closeText}>
              <CloseIcon />
            </IconButton>
          </DialogPrimitive.Close>
        }
        setIsOpen={setIsDialogOpen}></SearchChatBotForm>
    </SearchChatBotDialogWrapper>
  );
};

export default SearchChatBot;

const usePlaceholderComponentContent = <T extends ComponentRendering>(
  placeholderName: string,
  componentName: string,
): T | null => {
  const { placeholders } = useContent();
  const placeholder = placeholders?.[placeholderName];
  if (!placeholder) return null;
  const component = placeholder.find(rendering => rendering.componentName === componentName);
  if (!component) return null;
  return component as T;
};
